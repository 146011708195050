import React from 'react'
import { Link, graphql } from 'gatsby'
import { getImage } from "gatsby-plugin-image"
import { BgImage } from "gbimage-bridge"

//fontawsome icons
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faTwitter,
  faLinkedinIn,
  faFacebookF,
} from "@fortawesome/free-brands-svg-icons";

//components
import Layout from "../../components/layout";
import Seo from "../../components/seo";
import StayLoop from "../../components/Home/stayloop";
import MoreArticles from "../../components/Home/morearticles";

const BlogSingle = (props) => {
  const { blog: post } = props.data
  const SEOdata = props.data.awardpagedata.edges[0].node.frontmatter?.seo_metadata
  const Schemadata = props.data.awardpagedata.edges[0].node.frontmatter?.Schema
  const Hreflang = props.data.awardpagedata.edges[0].node.frontmatter?.Hreflang
  const { edges: morepost } = props.data.moreArticles
  return (
    <Layout>
      <Seo
        title={SEOdata ? SEOdata.seo_title : "8 Tech Trends 2021 That Will Be Game Changers For The Businesses"}
        description={SEOdata ? SEOdata.seo_description : ""}
        keywords={SEOdata ? SEOdata.seo_keyword : ""}
        image={SEOdata ? SEOdata.seo_Image?.childImageSharp?.gatsbyImageData?.images?.fallback?.src : ''}
        single_image={SEOdata ? SEOdata.seo_Single_Image : ''}
        currentPageUrlPath={props.location.pathname}
        image_format={SEOdata ? SEOdata.image_format : ""}
        image_height={SEOdata ? SEOdata.feature_image_height : ""}
        img_width={SEOdata ? SEOdata.img_width : ""}
        facebook_url={SEOdata ? SEOdata.facebook_url : ""}
        twitter_page_username={SEOdata ? SEOdata.twitter_page_username : ""}
        twitter_image_alt_tag={SEOdata ? SEOdata.twitter_image_alt_tag : ""}
        twitter_estimated_reading_lable={SEOdata ? SEOdata.twitter_estimated_reading_lable : ""}
        twitter_estimated_reading_time={SEOdata ? SEOdata.twitter_estimated_reading_time : ""}
        page_publish_date={SEOdata ? SEOdata.page_publish_date : ""}
        page_last_modified_time={SEOdata ? SEOdata.page_last_modified_time : ""}
        sitelinks_schema={Schemadata ? Schemadata.sitelinks_schema : ""}
        breadcrumb_schema={Schemadata ? Schemadata.breadcrumb_schema : ""}
        logo_schema={Schemadata ? Schemadata.logo_schema : ""}
        organization_schema={Schemadata ? Schemadata.organization_schema : ""}
        aggregate_rating_schema={Schemadata ? Schemadata.aggregate_rating_schema : ""}
        local_business_schema={Schemadata ? Schemadata.local_business_schema : ""}
        site_navigation_schema={Schemadata ? Schemadata.site_navigation_schema : ""}
        speakable_specification_schema={Schemadata ? Schemadata.speakable_specification_schema : ""}
        hreflang1={Hreflang ? Hreflang.hreflang_1 : ""}
        hreflang2={Hreflang ? Hreflang.hreflang_2 : ""}
        hreflang3={Hreflang ? Hreflang.hreflang_3 : ""}
        hreflang4={Hreflang ? Hreflang.hreflang_4 : ""}
      />


      {/* blog description section */}

      <section className="mt-60 lg:mt-20 pt-6-6 pb-35 lg:pt-51 lg:pb-59">
        <div className="container mx-auto">
          <div className="flex flex-wrap justify-between">
            <div className="hidden xl:block w-full lg:w-1/6"></div>
            <div className="w-full lg:w-4/5 xl:w-2/3">
              <h2 className="mb-3-3 lg:mb-1-3 text-rh2 sm:text-h3 lg:text-h2 font-poppings-bold text-term-primary">{post.frontmatter.title}</h2>
              <div className="blog-inner--cnt text-p4 lg:text-p2 font-worksans-normal text-term-primary"><p>The Covid-19 pandemic accelerated automation, which helped some companies remain resilient in adversity. Across the globe, innovation, agility, and market development are accelerated by technological advancements. Many businesses adopted new technologies and modified their business models.</p>
                <p>Although we will feel the impact of the pandemic for a long time, the digital transition will continue. Businesses must keep up with these technological trends, because advanced technology will continue to change the way we operate. This means new opportunities for businesses.</p>
                <p>Here are 8 of the latest developments to adopt for your company.</p>
                <ul>
                  <li>AI is a Must Have</li>
                </ul>
                <p>Artificial Intelligence is redefining technology and business. Most businesses are adopting AI strategy in some way. Although it’s not widespread, one day it will be. Large enterprises are quickly adopting AI into their organizational development. In the near future there will be algorithm-based marketplaces, operations and more. With AI and machine learning an active part of business, data-driven algorithms are in high demand. AI is applicable to every industry, from banking and finance to healthcare and manufacturing. With the need for automation, remote assistance and virtual existence increasing by the day, it’s time to incorporate AI into your business model.</p>
                <ul>
                  <li>Blockchain Opportunities</li>
                </ul>
                <p>Blockchain is a recent mainstream tech trend that many people believe is about Cryptocurrency, but it’s not. Bitcoin and other Cryptocurrencies are just a part of Blockchain technology. Blockchain is used in healthcare, supply chain and logistics, advertising, etc.</p>
                <p>It's a decentralized digital ledger that you can use to track transactions on a computer network. Many need Blockchain to build top-level strategies, making it a hot commodity. It also offers protection and transparency which is particularly valuable.</p>
                <ul>
                  <li>IoB (Internet of behaviors)</li>
                </ul>
                <p>If you've heard about the Internet of Things (IoT), you know that it extends to the Internet of Behavior (IoB) as well. IoT is concerned with using data and insights to influence people’s behavior. IoT devices acan act as massive databases for IoB paradigms.</p>
                <p>Businesses can follow customer behavior and use IoB to their benefit. For example, a health-tracking app may collect information about your physical activity routine, diet, sleep, and additional habits. This information is used to encourage more behavioral improvement, like custom health plans.</p>
                <ul>
                  <li>Enhance data collection</li>
                </ul>
                <p>Data collection is a major part of how enterprises gather insights and increase efficiency. Data equals success. If you experience complications in the computations or algorithms, you will have complications in your data collection. Most hardware innovations are focused on improving the deep learning abilities in the machine for better performance. Example: GPUs with improved architecture.</p>
                <p>Understanding data capabilities and deep learning you can see why so many organizations are taking on data-driven methods for better operations. A lot of data collection methods are organized from CRM, which increases the quality of data available in an organization. This looks like more data opportunities for gathering, analysis and insight.</p>
                <ul>
                  <li>More than platform-only business:</li>
                </ul>
                <p>For years we lived with platform-only business models. What does this mean? People or organizations make exchanges over a platform, but today’s technology demands more. You cannot remain stuck in the platform idea to build your business and give it the required tech scalability. You need assets, which can be acquired through mergers and acquisitions. Uber and AirBnb are two companies who have made this a reality.</p>
                <ul>
                  <li>Privacy is a core business strategy:</li>
                </ul>
                <p>Privacy and security are two pillars of business and the need to strengthen them is growing with the increased role of tech. For instance, you to maintain data security and keep your assets private. If there are cracks in the technology or the assets involved, you might as well say goodbye to success. Data breaches aren’t a good look. In 2022, viable approaches will be introduced for better security and increased privacy. Machine learning will help security via a predictive approach. Blockchain technology can also help you implement privacy and security at different levels. You will need to evaluate risks and establish mitigation strategies that work.</p>
                <ul>
                  <li>Cybersecurity</li>
                </ul>
                <p>Cybersecurity may not seem cutting-edge because it;s been around for so long, but it progresses at the same rate as other technologies. This is because new threats emerge all the time and cybersecurity shifts with those threats.</p>
                <p>Malicious hackers attempting to access unauthorized data don’t give up quickly, and will find ways around the most stringent protection measures. Since Cybersecurity has to guard against hackers as long as we have them, this popular technology is not going anywhere anytime soon.</p>
                <ul>
                  <li>Distributed cloud technology</li>
                </ul>
                <p>The Distributed Cloud technology trend is poised to bring Cloud Computing to the next level. Distributing cloud technology shares public cloud resources to many geographical locations, processes, updates, delivery, and relevant activities from one central public cloud provider.</p>
                <p>Instead of offering a centralized solution, it helps to fulfill the service needs of individual cloud locations separately. Meanwhile, companies benefit from this technological trend by decreasing latency, reducing data loss, and lowering costs.</p>
                <p>Technologies such as Artificial Intelligence (AI), the Internet of Things (IoT), and others, which process large amounts of data in real-time, benefit from Distributed Cloud technology</p></div>
            </div>
            <div className="w-full lg:w-1/6">
              <div className="w-full lg:w-3/4 text-left lg:text-right flex flex-wrap lg:flex-col justify-between">
                <div className="lg:mb-39">
                  <h5 className="lg:mb-1-1 uppercase text-cyan font-poppings-bold text-rh6 md:text-h6">
                    {post.frontmatter.blog_category}
                  </h5>
                  <p className="text-term-primary font-worksans-normal text-p4 lg:text-p3">
                    {post.frontmatter.date}
                  </p>
                </div>
                <div className="flex items-center lg:items-end lg:flex-col ">
                  <Link to={post.frontmatter.twitter} target="_blank" className="text-right opacity-20 hover:opacity-100 transform transition duration-500 ease">
                    <FontAwesomeIcon
                      className="font-awesome text-2xl text-term-primary "
                      icon={faTwitter}
                    />
                  </Link>
                  <Link to={post.frontmatter.linkedin} target="_blank" className="text-right opacity-20 hover:opacity-100 transform transition duration-500 ease">
                    <FontAwesomeIcon
                      className="mx-8 lg:mx-0 lg:my-5-1 font-awesome text-2xl text-term-primary "
                      icon={faLinkedinIn}
                    />
                  </Link>
                  <Link to={post.frontmatter.facebook} target="_blank" className="text-right opacity-20 hover:opacity-100 transform transition duration-500 ease">
                    <FontAwesomeIcon
                      className="font-awesome text-2xl text-term-primary"
                      icon={faFacebookF}
                    />
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* more articles section */}
      {morepost.length &&
        <section className="pb-50 lg:pb-1-04">
          <MoreArticles articles={morepost} />
        </section>
      }

      {/* stay in loop section */}
      <StayLoop />

    </Layout>

  )
}

export default BlogSingle

export const BlogPageQuery = graphql`
  query BlogPage1($id: String!) {
    blog: markdownRemark(id: { eq: $id }) {
      id
      frontmatter {
        title
        blog_category
        templateKey
        facebook
        linkedin
        twitter
        date(formatString: "DD MMM, YYYY")
        
      }
    }
    moreArticles: allMarkdownRemark(
      filter: { 
              id: { ne: $id },
              frontmatter: { templateKey: { eq: "single-blog" } }
            }
      ) {
       edges {
         node {
           id
           fields {
             slug
           }
           frontmatter {
             title
             blog_category
             templateKey
             featured_image {              
              image {
                childImageSharp {
                  gatsbyImageData(
                    placeholder: BLURRED
                  )
                }
              }
            }
             date(formatString: "DD MMM, YYYY")
           }
         }
       }
     }
     awardpagedata: allMarkdownRemark(filter: {id: {eq: $id}}) {
      edges {
        node {
          frontmatter {
            Hreflang {
              hreflang_1
              hreflang_3
              hreflang_2
              hreflang_4
            }
            Schema {
              aggregate_rating_schema
              breadcrumb_schema
              local_business_schema
              logo_schema
              organization_schema
              site_navigation_schema
              sitelinks_schema
              speakable_specification_schema
            }
            seo_metadata {
              facebook_url
              feature_image_height
              image_format
              img_width
              page_last_modified_time
              page_publish_date
              seo_description
              seo_keyword
              seo_title
              twitter_estimated_reading_lable
              twitter_estimated_reading_time
              twitter_image_alt_tag
              twitter_page_username
              seo_Image {
                childImageSharp {
                  gatsbyImageData(layout: FIXED, width: 1200)
                }
              }
              seo_Single_Image
            }
          }
        }
      }
    }
  }`